import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/Layout"
import CTA from "../components/CTA"
import Reel from "../components/Reel"
import SEO from "../components/SEO"
import TextBlock from "../components/TextBlock"
import "./state.scss"

const State = (props) => {
  const { data } = props
  const state = data.markdownRemark.frontmatter
  const siteTitle = data.site.siteMetadata?.title || `Fabricsthan`
  const pageTitle = state.title

  const fabrcisFrom = data.allMarkdownRemark.edges?.length
    ? data.allMarkdownRemark.edges.map((i) => ({
        name: i.node.frontmatter.title,
        slug: i.node.fields.slug,
        img: i.node.frontmatter.thumbnail,
      }))
    : []
  return (
    <Layout bgAlt={siteTitle}>
      <SEO
        title={`${pageTitle} | ${siteTitle} | Fabric Of India`}
        description={state.meta_description}
        image={state.thumbnail}
        pageContext={props.pageContext}
      />
      <div id="state-page" className="container">
        <div className="header-block">
          <div
            className="container-div"
            style={{ backgroundImage: `url(${state.thumbnail})` }}
          >
            <p className="sub-heading">Fabrics Of India</p>
            <h1>{pageTitle}</h1>
          </div>
        </div>
        {state.description && (
          <TextBlock
            title={state.description_title}
            text={state.description}
            imgSrc={state.description_image}
            extraClass="text-block-1"
          />
        )}
        {state.second_block && (
          <TextBlock
            title={state.second_block_title}
            text={state.second_block}
            imgSrc={state.second_block_image}
            extraClass="text-block-2"
          />
        )}
        {fabrcisFrom.length > 0 && (
          <div>
            <h3>Checkout fabrics made in {pageTitle}</h3>
            <Reel items={fabrcisFrom} showAsTiles />
          </div>
        )}
        <CTA
          heading="Want to see fabrics from the states in India?"
          buttonLabel="Fabrics Of India"
          buttonLink="/fabrics-of-india"
        />
      </div>
    </Layout>
  )
}

export default State
export const pageQuery = graphql`
  query StatePost(
    $id: String!
    $title: String
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    # Fetch all fabrics that have this state
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/fabric/.*.md/" }
        frontmatter: { state: { in: [$title] } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            fabric_base
            thumbnail
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description_title
        description
        description_image
        second_block_title
        second_block
        second_block_image
        thumbnail
        meta_description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
